import React, { useEffect } from 'react';
import Link from "next/link";
import { useTranslation } from 'react-i18next';

const Custom404 = () => {

  const { t } = useTranslation("header");

  useEffect(() => {
    // Get the current URL path and extract the language segment
    const currentPath = window.location.pathname;
    const segments = currentPath.split('/').filter(Boolean); // Remove empty segments
    const language = segments[0] || ''; // Default to '' if no language segment exists
    
    //console.log('useEffect triggered in 404.js');

    // Redirect to the language's home page
    window.location.replace(`/${language}`);
  }, []);

  return (
    <div>
      <div className="header-section py-3.5 lg:py-6 fixed w-full transition-all top-0 lg:z-[100] z-[45] bg-gray-light-3">
        <div className="container mx-auto z-0 lg:px-8 px-4">
          <header className="bg-transparent">
            <nav className="mx-auto flex items-center justify-between" aria-label="Global">
              <div className="flex lg:flex-1 items-center">
                <Link href="/" className="pr-4 items-center inline-flex xl:border-r-2 rtl:xl:border-l-2 rtl:border-r-0 rtl:pl-4 rtl:pr-0 xl:border-gray-light" >
                  <span className="sr-only">Siraaf</span>
                  <img
                    loading="lazy"
                    width="160"
                    height="35"
                    srcSet="/_next/image?url=%2Fimages%2Flogo2xl.png&amp;w=256&amp;q=75 1x, /_next/image?url=%2Fimages%2Flogo2xl.png&amp;w=384&amp;q=75 2x"
                    src="/_next/image?url=%2Fimages%2Flogo2xl.png&amp;w=384&amp;q=75"
                    style={{ color: 'transparent' }}
                    alt="Logo"
                  />
                </Link>
                <Link
                  href="/fa"
                  dir="ltr"
                  className="h-10 py-1 px-3 text-black inline-flex items-center border-2 rounded-md border-gray-light hover:border-gray-light-2 transition-all ml-4 rtl:ml-0 rtl:mr-4 hidden xl:inline-flex"
                >
                  <svg width="17" height="11" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
                    <path fill="red" d="M17 11H0V7h17z"></path>
                    <path fill="#fff" d="M17 7H0V3h17z"></path>
                    <path fill="#008435" d="M17 4H0V0h17z"></path>
                  </svg>
                  FA
                </Link>
                <form className="hidden xl:flex" autoComplete="off">
                  <span className="relative block">
                    <input
                      type="text"
                      className="w-full block h-10 px-4 py-1.5 text-black outline-none font-open-sans rtl:font-dana-r text-base font-light rounded-lg border border-gray-light-5 ml-4 rtl:ml-0 rtl:mr-4 pr-10 rtl:pr-4 rtl:pl-10 bg-transparent hover:border-gray-light-2 transition-all"
                      placeholder="Search"
                      name="search_key"
                      autoComplete="off"
                    />
                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute cursor-pointer -right-2 rtl:-left-2 rtl:right-auto top-2 [&>path]:stroke-black">
                      <path d="M11.5 21a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19ZM22 22l-2-2" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                  </span>
                </form>
              </div>
              <div className="flex lg:hidden">
                <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
                  <span className="sr-only">Open main menu</span>
                  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" aria-hidden="true">
                    <path d="M3 7h18M3 12h18M3 17h18" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"></path>
                  </svg>
                </button>
              </div>
              <div className="hidden lg:flex xl:gap-x-8 lg:gap-x-4 mr-10 rtl:mr-0 rtl:ml-10 transition-all">
                <div className="relative group transition hover:text-blue text-gray-dark text-center">
                  <Link href="/ads">
                    <span className="absolute -left-2 rtl:-right-2 transition top-2.5 rounded-full bg-transparent group-hover:bg-blue w-1 h-1 inline-block"></span>
                    {t('all_ads')}
                  </Link>
                  <div className="z-30 absolute -left-2 rtl:-right-2 top-full hidden group-hover:flex bg-gray-light-3 divide-y divide-gray-100 rounded-lg rounded-tl-none shadow-md w-56">
                    <ul className="py-2 text-sm text-left rtl:text-right text-gray-700">
                      <li><Link href="/ads/purchase-request" className="block py-2 px-4 text-base text-black hover:text-blue" >{t('purchase_request')}</Link></li>
                      <li><Link href="/ads/sell-offer" className="block py-2 px-4 text-base text-black hover:text-blue" >{t('sales_order')}</Link></li>
                      <li><Link href="/ads/ready-to-ship" className="block py-2 px-4 text-base text-black hover:text-blue" >{t('ready_to')}</Link></li>
                    </ul>
                  </div>
                </div>
                <Link href="/companies" className="relative group transition hover:text-blue text-gray-dark text-center" >
                  <span className="absolute -left-2 rtl:-right-2 transition top-2.5 rounded-full bg-transparent group-hover:bg-blue w-1 h-1 inline-block"></span>
                  {t('companies')}
                </Link>
                <Link href="/blogs" className="relative group transition hover:text-blue text-gray-dark text-center" >
                  <span className="absolute -left-2 rtl:-right-2 transition top-2.5 rounded-full bg-transparent group-hover:bg-blue w-1 h-1 inline-block"></span>
                  {t('blogs')}
                </Link>
                <Link href="/about-us" className="relative group transition hover:text-blue text-gray-dark text-center" >
                  <span className="absolute -left-2 rtl:-right-2 transition top-2.5 rounded-full bg-transparent group-hover:bg-blue w-1 h-1 inline-block"></span>
                  {t('about_us')}
                </Link>
              </div>
              
            </nav>
          </header>
        </div>
      </div>

      <div className='container mx-auto z-0 lg:px-8 px-4'>

        <div className="flex justify-center w-full items-center">
          <div className="flex-initial lg:mr-4 xl:mr-16 rtl:lg:ml-4 rtl:xl:ml-16 rtl:mr-0">
            <h1 className="text-4xl text-black text-center lg:text-left rtl:lg:text-right font-open-sans rtl:font-dana-r my-4">
              <span className="text-blue">Siraaf</span> international <span className="text-blue">B2B</span> market, direct trade platform between buyer and seller
            </h1>
          </div>
          <div className="lg:flex-grow lg:flex hidden">
            <hr className="w-full h-0.5 my-8 bg-gradient-primary border-0" />
          </div>
          </div>
      </div>

      <div className='container mx-auto z-0 lg:px-8 px-4'>
        <div className='py-10'>

        <div className='relative overflow-hidden scroll-smooth sm:px-10 px-4 my-10 py-6 pb-10 rounded-lg bg-white'>
          <h1 className='text-black text-2xl mb-6 capitalize'>
          Oops! Page Not Found
          </h1>
          <p className='text-sm text-ellipsis text-black text-justify font-open-sans rtl:font-dana-r mt-3 overflow-hidden transition-all max-h-[60px]'>
          It looks like the page you're looking for doesn’t exist.
          </p>
          <p className='text-sm text-ellipsis text-black text-justify font-open-sans rtl:font-dana-r mt-3 overflow-hidden transition-all max-h-[60px] mb-5'>
          But don’t worry! Here are some things you can do:
          </p>
          <ul>
            <li className='text-black group-hover:text-blue-2 transition-all flex items-center  px-5 font-open-sans rtl:font-dana-r font-bold text-base'>
            - Check the URL for any typos.
            </li>
            <li className='text-black group-hover:text-blue-2 transition-all flex items-center  px-5 font-open-sans rtl:font-dana-r font-bold text-base'>
            - Head back to our Home Page.

            </li>
            <li className='text-black group-hover:text-blue-2 transition-all flex items-center  px-5 font-open-sans rtl:font-dana-r font-bold text-base'>
            - Explore our Categories to find what you need.

            </li>
            <li className='text-black group-hover:text-blue-2 transition-all flex items-center  px-5 font-open-sans rtl:font-dana-r font-bold text-base'>
            - If you’re lost, feel free to Contact Us for help!

            </li>
            <li className='text-black group-hover:text-blue-2 transition-all flex items-center  px-5 font-open-sans rtl:font-dana-r font-bold text-base'>
            - We’re here to assist you!

            </li>
          </ul>

          <p className='text-sm text-ellipsis text-black text-justify font-open-sans rtl:font-dana-r mt-3 overflow-hidden transition-all max-h-[60px] mb-5'>
          {t('redirecting_home', 'Redirecting to the home page...')}
          </p>

        </div>
        </div>

      </div>

      
      
      

    </div>

    
  );
};

export default Custom404;
